import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ clicked, children, type, disabled, customClasses, id }) => {
  const classNames = !customClasses
    ? 'button button--gradient-primary button--rounded button--xlg'
    : customClasses;
  return (
    <button
      type={type}
      className={classNames}
      onClick={clicked}
      disabled={disabled}
      id={id}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  clicked: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  customClasses: PropTypes.string,
  id: PropTypes.string
};

export default Button;
